<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-forms :forms="forms" @editForm="editForm" @getId="getId"></table-forms>
    </div>
    <modal-form @refresh="refresh"></modal-form>
    <modal-form-edit :id="id" @refresh="refresh"></modal-form-edit>
    <modal-form-handler
      @editForm="editForm"
      :id="id"
      @sendDataDriver="sendDataDriver"
    ></modal-form-handler>
    <modal-driver :carId="carId" :car_info="car_info" :driver="driver" />
    <modal-driver-car
      @sendCarInfo="sendCarInfo"
      @connectCar="connectCar"
    ></modal-driver-car>
    <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
  </div>
</template>
<script>
import tableForms from "@/views/component/Table/tableForms.vue";
import modalForm from "@/views/component/Modal/ModalDriver/modalForm.vue";
import modalFormEdit from "@/views/component/Modal/ModalDriver/modalFormEdit.vue";
import modalFormHandler from "@/views/component/Modal/ModalDriver/modalFormHandler.vue";
import modalDriver from "@/views/component/Modal/ModalDriver/modalDriver.vue";
import modalDriverCar from "@/views/component/Modal/ModalDriver/modalDriverCar.vue";


export default {
  components: {
    tableForms,
    modalForm,
    modalFormEdit,
    modalFormHandler,
    modalDriver,
    modalDriverCar,
  },
  data() {
    return {
      id: "",
      showPreloader: false,
      forms: [],
      fields: [
                { key: 'id', label: 'Id'},
                { key: 'division', label: 'Подразделение'},
                { key: 'last_name', label: 'Фамилия'},
                { key: 'phone_number', label: 'Телефон'},
                { key: 'comment', label: 'Комментарий'},
                { key: 'status', label: 'Статус' },
            ],
            car: [],
            driver: null,
            car_info: null,
      carId: "",
      perfomerPhone: null
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.openFilter();
  },
  methods: {
    //     filteredCars(cars){
    //        this.cars=cars
    //    },
    sendDataDriver(driver) {
      this.driver = driver;
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      if(this.perfomerPhone) {
        param.filter_phone_number = this.perfomerPhone;
      }
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.forms = res.data;
          this.$store.commit("pageData/setdataCount", this.forms.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
      this.$store.state.filterPlus.filterValue = null
    },
    editForm(id) {
      this.id = id;
      setTimeout(this.openModal, 0);
    },
    getId(id) {
      this.id = id;
    },
    sendToParent(tableData) {
      this.forms = tableData;
      this.$store.commit("pageData/setdataCount", this.forms.length);
    },
    sendToParents(car) {
      this.car = car;
    },
    connectCar(car_id) {
      this.carId = car_id;
    },
    sendCarInfo(car_info) {
      this.car_info = car_info;
    },
    openModal() {
      this.$bvModal.show("formModalEdit");
    },
    refresh() {
      this.showPreloader = true;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.forms = res.data;
          this.$store.commit("pageData/setdataCount", this.forms.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        }); 
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    '$store.state.filterPlus.filterValue': {
      immediate: true,
      handler(newFilterValue) {
          if (newFilterValue) {
              this.perfomerPhone = '992' + newFilterValue
              this.openFilter(); 
          }
      },
    }, 
    fetchingNewData(val) {
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      if (val) {
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.forms = res.data;
          this.$store.commit("pageData/setdataCount", this.forms.length);
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>
