<template>
  <b-modal
  hide-footer no-enforce-focus
  id="formModalEdit"
  title="Изменить анкету"
  size="md"
  @show="getData()"
  @hide="form='', submitButtonDisabled = false, incorrectPlateNumber = false"
  @shown="detectStartTime"
  @hidden="detectEndTime"
  >
    <b-col cols="12">
      <b-row>
        <b-col cols="12">
          <label for=""> Подразделение</label>
          <v-select v-model="form.division_id" placeholder="Подразделение" :reduce="option => option.id" label="name" :options="divisions" class="select-size-md" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <label for="">Имя</label>
          <b-input type="text" placeholder="Тошпулод" v-model="form.first_name"></b-input>
        </b-col>
        <b-col cols="4">
          <label for=""> Фамилия</label>
          <b-input type="text" placeholder="Тошпулодов" v-model="form.last_name"></b-input>
        </b-col>
        <b-col cols="4">
          <label for="">Отчество</label>
          <b-input type="text" placeholder="Тошпулодович" v-model="form.patronymic"></b-input>
        </b-col>
      </b-row>
      <hr>
      <h5>Личные данные</h5>
      <b-row>
        <b-col cols="4">
          <label for="">Дата рождения</label>
          <b-input @input="validate()" type="date" :max="maxDate"  v-model="form.date_of_birth"></b-input>
          <p class="mt-1" v-if="form.date_of_birth > maxDate" style="font-size: 11px; color:red">
          Ошибка! Водитель не может быть младше 18 лет
          </p>
        </b-col>
        <b-col cols="4">
          <label for=""> Телефон</label>
          <b-input type="text" v-model="form.phone"></b-input>
        </b-col>
        <b-col cols="4">
          <label for="">Доп. телефон</label>
          <b-input type="text" v-model="form.dop_phone"></b-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
            <label for="" class="pr-1">Документ</label>
            <b-input type="text" v-model="form.serial_number_passport"></b-input>
        </b-col>
        <b-col cols="6">
          <label for="" class="pr-1">Дата выдачи</label>
          <b-input type="date" v-model="form.expirated_passport"></b-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-row class="d-flex align-items-center mt-2">
            <b-col cols="4">
              <label for="">Кем выдан</label>
            </b-col>
            <b-col cols="8">
              <v-select v-model="form.passport_office_id" :reduce="option => option.id" label="name" :options="passport_offices"></v-select>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row class="d-flex align-items-center mt-2">
            <b-col cols="4">
              <label for="">Адрес проживания</label>
            </b-col>
            <b-col cols="8">
              <b-input v-model="form.address" type="text"></b-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr>
      <h5>Водительское удостоверение</h5>
      <b-row>
        <b-col cols="4">
          <label for="">Тип</label>
          <v-select v-model="form.driver_license_type_id" :reduce="option => option.id" label="name" :options="driver_license_types"></v-select>
        </b-col>
        <b-col cols="4">
          <label for="">Номер</label>
          <b-input v-model="form.serials_number"></b-input>
        </b-col>
        <b-col cols="4">
          <label for="">Действителен до</label>
          <b-input v-model="form.expirated_driver_license" type="date"></b-input>
        </b-col>
      </b-row>
      <hr>
        <h5>Автомобиль</h5>
        <b-row>
          <b-col cols="6">
            <label for="">Номер</label>
            <b-input v-model="form.car_number" placeholder="1111AA01" v-mask="'####AA##'" @input="incorrectPlateNumber=false, submitButtonDisabled=false" @blur="useRegex(form.car_number)"></b-input>
            <p class="mt-1" v-if="incorrectPlateNumber" style="font-size: 11px; color:red">
              Не правильный номер машины
            </p>
          </b-col>
          <b-col cols="6">
            <label for="">Год выпуска</label>
            <b-input type="number" v-mask="'####'" @input="validate()" v-model="form.year"></b-input>
            <p class="mt-1" v-if="form.year > currentYear" style="font-size: 11px; color:red">
          Ошибка! Год выпуска не может быть после {{currentYear}} года
          </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <label for="">Марка</label>
            <v-select v-model="form.model_id" :reduce="option => option.id" label="name" :options="carModel"></v-select>
          </b-col>
          <b-col cols="6">
            <label for="">Цвет</label>
            <v-select v-model="form.color_id" :reduce="option => option.id" label="name" :options="carColor"></v-select>
          </b-col>
        </b-row>
      <hr>
      <b-row>
        <b-col cols="6">
          <label for="">История</label>
          <b-textarea></b-textarea>
        </b-col>
        <b-col cols="6">
          <label for="">Комментарий</label>
          <b-textarea v-model="form.comment"></b-textarea>
        </b-col>
      </b-row>
    </b-col>
    <div class="d-flex justify-content-end">
      <b-col class="mt-1">
        <div>{{ dateTime.hours }}:{{ dateTime.minutes < 10 ? '0' + dateTime.minutes : dateTime.minutes}}</div>
      </b-col>
        <b-button
        :disabled="submitButtonDisabled"
        class="col-md-3 mt-1 align-self-end" variant="primary" size="sm" block @click="editForm()">
            Ок
        </b-button>
    </div>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import { mask } from "vue-the-mask"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const date = new Date()
export default {
  props: ['id'],
  components: {
    vSelect,
    ToastificationContent
  },
  directives: { mask },
  data() {
    return {
      currentYear: '',
      maxDate: '',
      divisions: [],
      formTime: {
        form_name: null,
        start_time: null,
        end_time: null,
      },
      incorrectPlateNumber: false,
      form: {
        division_id: '',
        first_name: '',
        last_name: '',
        patronymic: '',
        phone: '',
        dop_phone: '',
        comment: '',
        date_of_birth: '',
        driver_license_type_id: '',
        information_sources_id: '',
        serials_number: '',
        expirated_driver_license: '',
        serial_number_passport: '',
        expirated_passport: '',
        passport_office_id: '',
        address: '',
        color_id: '',
        model_id: '',
        year: '',
        car_number: '',
      },
      carModel: [],
      carColor: [],
      driverProfilesData: null,
      passport_offices: [],
      driver_license_types: [],
      submitButtonDisabled: false,
      prompts: false,
      types: [],
      dateTime: {
        hours: date.getHours(),
        minutes: date.getMinutes()
      },
    }
  },
  mounted() {
    setInterval(this.setDateTime, 1000);
  },
  methods: {
    detectEndTime() {
      this.formTime.end_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      this.$http.post('time-form', this.formTime)
      .then((res) => {
      })
      .catch((err) => {
      }) 
    },
    detectStartTime() {
      this.formTime.form_name = 'requestEditForm'
      this.formTime.start_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
    },
    validate(){
      this.submitButtonDisabled = false
      if(this.form.date_of_birth > this.maxDate) {
        this.submitButtonDisabled = true
      }
      if(this.form.year > this.currentYear) {
        this.submitButtonDisabled = true
      }
    },

    useRegex(input) {
      let regex = /^\d\d\d\d[a-z][a-z]0\d$/i;
      this.incorrectPlateNumber = !regex.test(input)
      if(this.incorrectPlateNumber){
        this.submitButtonDisabled = true
      }
      else {
        this.submitButtonDisabled = false
      }

      if(this.form.car_number == "" || this.form.car_number == null) {
        this.incorrectPlateNumber = false
        this.submitButtonDisabled = false
      }
    },

    setDateTime() {
      const date = new Date();
      this.dateTime = {
        hours: date.getHours(),
        minutes: date.getMinutes()
      };
      this.currentYear = date.getFullYear();
      date.setDate(date.getDate()-6570);
      this.maxDate = date.toISOString().split('T')[0]
    },
    editForm(){
      // if (this.form.patronymic == null || this.form.patronymic == "") {
      //   this.form.patronymic = undefined
      // }
      // if (this.form.dop_phone == null || this.form.dop_phone == "") {
      //   this.form.dop_phone = undefined
      // }
      // if (this.form.comment == null || this.form.comment == "") {
      //   this.form.comment = undefined
      // }

      if (this.form.serial_number_passport == "" ) {
        this.form.serial_number_passport = undefined
      }

      if (this.form.expirated_driver_license == "" ) {
        this.form.expirated_driver_license = undefined
      }

      if (this.form.expirated_passport == "" ) {
        this.form.expirated_passport = undefined
      }

      this.submitButtonDisabled = true
      this.$http
        .patch(`driver-profiles/${this.id}`, this.form)
        .then(res => {
            this.$bvModal.hide('formModalEdit')
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Успех!',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: `Данные успешно изменены!`,
                },
            })
            this.$emit('refresh')
        })
        .catch(err => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                title: 'Некорректные данные!',
                icon: 'XIcon',
                variant: 'danger',
                text: err.response.data.errors,
                },
            })
        })
        .finally(()=>{
          this.submitButtonDisabled = false
        })
    },
    copyPhone(){
      navigator.clipboard.writeText(this.form.phone).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
          title: 'Номер скопирован!',
          icon: 'CheckIcon',
          variant: 'success',
          },
        })
      })
    },
    openPrompt(){
      this.prompts = !this.prompts
    },
    getData(){
      this.$http
      .get(`driver-profiles/${this.id}/edit`)
      .then(res =>{
        this.form = res.data
      })
      .catch(err =>{
      })
      
      this.$http
      .get('drivers/data')
      .then(res =>{
        this.divisions = res.data.divisions
      })
      .catch(err =>{
      })

      this.$http
      .get('driver-profiles/data')
      .then(res =>{
        this.driverProfilesData = res.data
        this.passport_offices = res.data.passport_offices
        this.driver_license_types = res.data.driver_license_types
      })
      .catch(err =>{
        console.log(err);
      })

      this.$http
      .get('car-settings/model-cars')
      .then(res=>{
        this.carModel = res.data
      })
      .catch(err =>{
        console.log(err);
      })

      this.$http
      .get('directory/cars')
      .then(res=>{
        this.carColor = res.data.car_colors
      })
      .catch(err =>{
        console.log(err);
      })
    }
  },
}
</script>
